$woocommerce: #ad74a2;

@mixin clearfix() {
	&:after {
		content:"";
		display:block;
		clear:both;
	}
}
@mixin border_radius($radius:4px) {
	-webkit-border-radius:$radius;
	-moz-border-radius:$radius;
	border-radius:$radius;
}
@mixin border_radius_right($radius:4px) {
	-webkit-border-top-right-radius: $radius;
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-radius-topright: $radius;
	-moz-border-radius-bottomright: $radius;
	border-top-right-radius: $radius;
	border-bottom-right-radius: $radius;
}
@mixin border_radius_left($radius:4px) {
	-webkit-border-top-left-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-radius-topleft: $radius;
	-moz-border-radius-bottomleft: $radius;
	border-top-left-radius: $radius;
	border-bottom-left-radius: $radius;
}
@mixin border_radius_bottom($radius:4px) {
	-webkit-border-bottom-left-radius: $radius;
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-radius-bottomleft: $radius;
	-moz-border-radius-bottomright: $radius;
	border-bottom-left-radius: $radius;
	border-bottom-right-radius: $radius;
}
@mixin border_radius_top($radius:4px) {
	-webkit-border-top-left-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	-moz-border-radius-topleft: $radius;
	-moz-border-radius-topright: $radius;
	border-top-left-radius: $radius;
	border-top-right-radius: $radius;
}
@mixin opacity($opacity:0.75) {
	filter: alpha(opacity=( $opacity * 100 ) );
	-moz-opacity:$opacity;
	-khtml-opacity: $opacity;
	opacity: $opacity;
}
@mixin box_shadow($shadow_x:3px, $shadow_y:3px, $shadow_rad:3px, $shadow_in:3px, $shadow_color:#888) {
	box-shadow:$shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
	-webkit-box-shadow:$shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
	-moz-box-shadow:$shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
}
@mixin inset_box_shadow($shadow_x:3px, $shadow_y:3px, $shadow_rad:3px, $shadow_in:3px, $shadow_color:#888) {
	box-shadow:inset $shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
	-webkit-box-shadow:inset $shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
	-moz-box-shadow:inset $shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
}
@mixin text_shadow($shadow_x:3px, $shadow_y:3px, $shadow_rad:3px, $shadow_color:#fff) {
	text-shadow:$shadow_x $shadow_y $shadow_rad $shadow_color;
}
@mixin vertical_gradient($from: #000, $to: #FFF) {
	background: $from;
	background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background: -webkit-linear-gradient($from, $to);
	background: -moz-linear-gradient(center top, $from 0%, $to 100%);
	background: -moz-gradient(center top, $from 0%, $to 100%);
}
@mixin transition($selector:all, $animation:ease-in-out, $duration:.2s) {
	-webkit-transition:$selector $animation $duration;
	-moz-transition:$selector $animation $duration;
	-o-transition:$selector $animation $duration;
	transition:$selector $animation $duration;
}

@mixin scale($ratio:1.5){
  -webkit-transform:scale($ratio);
  -moz-transform:scale($ratio);
  -ms-transform:scale($ratio);
  -o-transform:scale($ratio);
  transform:scale($ratio);
}

@mixin borderbox() {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}

.clear { clear: both; }
.nobr { white-space: nowrap; }

@mixin darkorlighttextshadow( $a, $opacity: 0.8 ) {
    @if lightness( $a ) >= 65% {
        @include text_shadow( 0, -1px, 0, rgba(0, 0, 0, $opacity) );
    } @else {
        @include text_shadow( 0, 1px, 0, rgba(255, 255, 255, $opacity) );
    }
}
