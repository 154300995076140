$primary: 			#289941; 									/* Primary colour for buttons (alt) */
$primarytext: 		desaturate(lighten($primary,50%),18%);		/* Text on primary colour bg */

$secondary: 		desaturate(lighten($primary,40%),18%);		/* Secondary buttons */
$secondarytext:	 	desaturate(darken($secondary,60%),18%);		/* Text on secondary colour bg */

$highlight: 		adjust-hue( $primary, 150 ); 						/* Prices, In stock labels, sales flash */
$highlightext:		desaturate(lighten($highlight,50%),18%);	/* Text on highlight colour bg */

$contentbg: 		#fff; 										/* Content BG - Tabs (active state) */
$subtext: 			#777; 										/* small, breadcrumbs etc */